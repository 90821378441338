<template lang="pug">
include ../../../configs/template
div.row.pt-3
  div.col-12
    +select-validation('body.level_qualification','listLevelQualification','qualification','nameLang','["required"]')(@input="getInstitution")
  div.col-12
    +select-validation('body.educational_institution','listInstitution','nameInstitution','nameLang','["required"]')
  div.col-12
    FileDropZone(ref="mediaContent")
  div.col-12
    v-btn(color="success" @click="saveStatement" :loading="isloading") {{ $t('btn.save') }}

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { CONST_TYPE_EDUCATION_INSTITUTION, SUCCESS_CODE } from '@/configs/constants'
import { education } from '@/mixins/validationRules'

const initBody = () => (
  {
    level_qualification: null,
    educational_institution: null
  }
)
export default {
  name: 'EducationStatementForm',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: initBody(),
      listInstitution: [],
      isloading: false
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['qualificationById']),
    listLevelQualification () {
      return this.qualificationById(CONST_TYPE_EDUCATION_INSTITUTION.CERTIFICATION_TRAINING)
    }

  },
  validations () { return education.statement() },

  mounted () {
    if (this.$route.params.documentID) {
      this.getInstitution(this.statement.level_qualification)
      Object.keys(this.body).forEach(key => (this.body[key] = this.statement[key]))
    }
  },
  methods: {
    ...mapActions(['getNameInstitution', 'setGraduationStatement', 'updateGraduationStatement']),
    async saveStatement () {
      this.isloading = true
      let data = {
        ...this.$route.params,
        body: { ...this.body },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'statement_advanced_training'
        }
      }
      const response = await this[`${this.$route.params.documentID ? 'update' : 'set'}GraduationStatement`](data)
      if (SUCCESS_CODE.includes(response.code)) {
        if (!this.$route.params.documentID) {
          this.$parent.isViewAddSlot = false
        }
        this.body = initBody()
        this.$v.$reset()
      }
      this.isloading = false
    },
    async getInstitution (qualification) {
      this.listInstitution = (await this.getNameInstitution({ qualification }))?.data || []
    }
  }
}
</script>
